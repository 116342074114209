import { combineReducers } from "redux";

import store from "./store/reducer";
import user from "./user/reducer";
import report from "./report/reducer";
import input from "./input/reducer";
import goal from "./goal/reducer";
import integration from "./integration/reducer";
import error from "./error/reducer";
import company from "./company/reducer";


export default combineReducers({
  store,
  user,
  report,
  input,
  goal,
  integration,
  error,
  company,
});
