import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    color: 'white',
    paddingLeft: 8,
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
}));

const Topbar = props => {
  const dispatch = useDispatch();
  const {
    className,
    onSidebarOpen,
    onLogoff,
    onInput,
    inputCount,
    storeName,
    ...rest
  } = props;

  const classes = useStyles();

  // const [notifications] = useState([]);

  const StyledButton = withStyles({
    root: {
      background:
        inputCount > 0
          ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
          : 'primary',
      borderRadius: 3,
      border: 5,
      color: 'white',
      height: 35,
      padding: '0 10px',
      boxShadow:
        inputCount > 0 ? '0 3px 5px 2px rgba(255, 105, 135, .3)' : undefined,
    },
    label: {
      color: 'white',
    },
  })(Button);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/" >
          <div className={classes.titleBox}>
            <img src="/images/logo.svg" alt="Logo" height="20px" style={{ margin: '10px 0px 10px 10px' }} />
            <Typography variant="h2" className={classes.title}>Matrix</Typography>
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={onLogoff}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  onInput: PropTypes.func,
  inputCount: PropTypes.number,
  storeName: PropTypes.string
};

export default Topbar;
