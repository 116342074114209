export function integrationRequest(data, provider, storeId, token) {
  return {
    type: '@integration/REQUEST',
    payload: { data, provider, storeId, token }
  }
}

export function integrationSuccess(dataRequest) {
  return {
    type: '@integration/SUCCESS',
    payload: { dataRequest }
  }
}

export function integrationDisconnectRequest(provider, storeId, token) {
  return {
    type: '@integration/DISCONNECT_REQUEST',
    payload: { provider, storeId, token }
  }
}

export function integrationDisconnectSuccess(dataRequest) {
  return {
    type: '@integration/DISCONNECT_SUCCESS',
    payload: { dataRequest }
  }
}