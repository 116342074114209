import React from 'react';

// Libs
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import { cpfMask, phoneMask } from '../../../utils/inputMasks'

const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    margin: theme.spacing(1),
  },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export default function AddUserModal({
  active,
  onClose,
  addUser,
  loading,
  companies,
  ...rest
}) {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, control } = useForm();

  const data = watch()

  const onSubmit = (submitData) => {
    let data = {
      name: {
        first: submitData.firstName,
        last: submitData.lastName,
      },
      gender: submitData.gender,
      email: submitData.email,
      birthDate: submitData.birthDate,
      phone: submitData.phone.replace(/[^a-zA-Z0-9]/g, "",),
      type: submitData.type,
      password: submitData.password,
      companyId: submitData.company,
    }

    if (submitData.cpf) data.cpf = submitData.cpf.replace(/[^a-zA-Z0-9]/g, "",)

    addUser(data)
  };

  const renderError = (error) =>
    errors[error] && (
      <FormHelperText id="component-error-text">
        Este campo é obrigatório
      </FormHelperText>
    );

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={active}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle id="form-dialog-title">Adicionar Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para adicionar um novo usuário.
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.firstName}
                >
                  <InputLabel htmlFor="firstName">Nome</InputLabel>
                  <OutlinedInput
                    id="firstName"
                    name="firstName"
                    inputRef={register({ required: true })}
                    labelWidth={37}
                    fullWidth
                  />
                  {renderError('firstName')}
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.lastName}
                >
                  <InputLabel htmlFor="lastName">Sobrenome</InputLabel>
                  <OutlinedInput
                    id="lastName"
                    name="lastName"
                    inputRef={register({ required: true })}
                    labelWidth={70}
                  />
                  {renderError('lastName')}
                </FormControl>
              </Grid>
            </Grid>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.company}
            >
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <Controller
                control={control}
                rules={{ required: true }}
                name="company"
                defaultValue=""
                as={
                  <Select labelWidth={55}>
                    {
                      companies && companies.map((company) => (
                        <MenuItem value={company._id} key={company._id}>{company.name}</MenuItem>
                      ))
                    }
                  </Select>
                }
              />
              {renderError('company')}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.margin}
              error={!!errors.type}
            >
              <InputLabel htmlFor="type">Tipo</InputLabel>
              <Controller
                control={control}
                id="type"
                name="type"
                inputRef={register({ required: true })}
                rules={{ required: 'Esse campo é obrigatório' }}
                labelWidth={28}
                as={
                  <Select>
                    <MenuItem value="user">Usuário</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                }
              />
              {renderError('type')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.cpf}
            >
              <InputLabel htmlFor="cpf">CPF</InputLabel>
              <OutlinedInput
                id="cpf"
                name="cpf"
                inputRef={register({ required: true })}
                labelWidth={25}
                value={data.cpf}
                inputComponent={cpfMask}
              />
              {renderError('cpf')}
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.margin}
              error={!!errors.gender}
            >
              <InputLabel htmlFor="gender">Sexo</InputLabel>
              <Controller
                control={control}
                id="gender"
                name="gender"
                inputRef={register({ required: true })}
                labelWidth={31}
                rules={{ required: 'this is required' }}
                as={
                  <Select>
                    <MenuItem value="female">Feminino</MenuItem>
                    <MenuItem value="male">Masculino</MenuItem>
                    <MenuItem value="other">Outro</MenuItem>
                  </Select>
                }
              />
              {renderError('gender')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.birthDate}
            >
              <InputLabel htmlFor="birthDate">Data de Nascimento</InputLabel>
              <Controller
                as={OutlinedInput}
                id="birthDate"
                name="birthDate"
                inputRef={register({ required: true })}
                control={control}
                startAdornment={
                  <InputAdornment position="start"> </InputAdornment>
                }
                labelWidth={125}
                type="date"
                error={!!errors.birthDate}
              />
              {renderError('birthDate')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.email}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                name="email"
                inputRef={register({ required: true })}
                labelWidth={34}
                type="email"
              />
              {renderError('email')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.phone}
            >
              <InputLabel htmlFor="phone">Telefone</InputLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                inputRef={register({ required: true })}
                labelWidth={55}
                value={data.phone}
                inputComponent={phoneMask}
              />
              {renderError('phone')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.password}
            >
              <InputLabel htmlFor="password">Senha</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                inputRef={register({ required: true })}
                labelWidth={38}
                type="password"
              />
              {renderError('password')}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {loading ? <CircularProgress size={24} /> : 'Adicionar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
