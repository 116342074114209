import produce from 'immer';

const INITIAL_STATE = {
  error: null,
};

export default function error(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'requestFailed': {
        draft.error = action.dataRequest;
        break;
      }
      default:
        return state;
    }
  });
}
