import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import GoalsTable from './components/GoalsTable'
import AddGoalModal from './components/AddGoalModal'
import Goal from './components/Goal'

import { Toolbar } from '../../../../components'

import {
  goalListRequest,
  goalAddRequest,
  handleOpenModal,
  handleCloseModal
} from '../../../../store/modules/goal/actions'
import { userListRequest } from '../../../../store/modules/user/actions'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4)
  }
}))

export default function Goals() {
  const userList = useSelector(state => state.user.userList)
  const userMetadata = useSelector(state => state.user.metadata)
  const goalList = useSelector(state => state.goal.goalList)
  const goalMetadata = useSelector(state => state.goal.metadata)
  const loading = useSelector(state => state.goal.loading)
  const modalGoal = useSelector(state => state.goal.modal)
  const store = useSelector(state => state.store.store)


  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const confirm = useConfirm()
  const token = window.localStorage.getItem('@Matrix:token')
  const [tab, setTab] = useState(0)
  const [goalId, setGoalId] = useState()
  const [hasSellers, setHasSellers] = useState(
    userList &&
    userList.length > 0 &&
    userList
      .filter(
        user =>
          user.stores.filter(
            store => store.storeId._id === store._id && store.type === 'seller'
          ).length > 0
      )
      .map(seller => seller._id).length > 0
  )

  useEffect(() => {
    if (goalMetadata.pagination.totalCount < 0) {
      dispatch(goalListRequest(store._id, token))
    }
    if (userMetadata.pagination.totalCount < 0) {
      dispatch(userListRequest(store._id, token))
    }
  }, [])

  useEffect(() => {
    setHasSellers(
      userList &&
      userList.length > 0 &&
      userList
        .filter(
          user =>
            user.stores.filter(
              store => store.storeId._id === store._id && store.type === 'seller'
            ).length > 0
        )
        .map(seller => seller._id).length > 0
    )
  }, [store, userList])

  const onAdd = () => {
    if (hasSellers) {
      dispatch(handleOpenModal())
    } else {
      confirm({
        title: 'Sem vendedores!',
        description: 'Você ainda não tem vendedores cadastrados, antes de criar uma meta, vá até a tela de usuários e registre pelo menos um vendedor.',
        confirmationText: 'Ir para lá!',
        cancellationText: 'Fechar'
      })
        .then(() => {
          history.push('/users')
        })
        .catch(() => { })
    }
  }

  const onRefresh = () => {
    dispatch(goalListRequest(store._id, token))
    dispatch(userListRequest(store._id, token))
  }

  const addGoal = response => {
    dispatch(goalAddRequest(response, token))
  }

  const handleChangePage = (page) => {
    if (page + 1 <= goalMetadata.pagination.pageCount) {
      dispatch(goalListRequest(
        store._id,
        token,
        page + 1,
        goalMetadata.pagination.limit
      ))
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(goalListRequest(
      store._id,
      token,
      goalMetadata.pagination.currentPage,
      Number(rowsNumber.props.value)
    ))
  }

  return (
    <div className={classes.root}>
      {/* <Toolbar
        onAdd={onAdd}
        onRefresh={onRefresh}
        actionText='Adicionar Meta'
      /> */}
      {tab === 0 && (
        <GoalsTable
          goals={goalList}
          metadata={goalMetadata}
          handleSelectGoal={(goalId) => {
            setGoalId(goalId)
            setTab(1)
          }}
          onChangeRows={handleChangeRows}
          onChangePage={handleChangePage}
        />
      )}
      {tab === 1 && (
        <Goal id={goalId} handleBack={() => setTab(0)} />
      )}
      {hasSellers && (
        <AddGoalModal
          active={modalGoal}
          loading={loading}
          onClose={() => dispatch(handleCloseModal())}
          onSubmit={addGoal}
          goals={goalList}
          sellers={userList.filter(
            user =>
              user.stores.filter(
                store => store.storeId._id === store._id && store.type === 'seller'
              ).length > 0)}
        />
      )}
    </div>
  )
}
