/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Libs
import { useSelector, useDispatch } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// Components
import AddUserModal from './components/AddUserModal';
import UsersTable from './components/UsersTable';
import User from './components/User';
import { Toolbar } from '../../components';

// Utils
import api from '../../repositories/api';

// Redux Actions
import { userListRequest, userAddRequest, userRequest, userAvatarUpdateRequest, userUpdateRequest } from '../../store/modules/user/actions';
import { companyListRequest } from "../../store/modules/company/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux State
  const userList = useSelector((state) => state.user.userList);
  const loading = useSelector((state) => state.user.loading);
  const metadata = useSelector((state) => state.user.metadata);
  const user = useSelector((state) => state.user.editUser);
  const companyList = useSelector(state => state.company.companyList);

  const token = localStorage.getItem('@Matrix:token');

  const [addModal, setAddModal] = useState(false);
  const [tab, setTab] = useState(0)

  // AutoComplete
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState();
  const [options, setOptions] = useState([]);
  const searchLoading = open && options.length === 0;

  useEffect(() => {
    if (metadata.pagination.totalCount < 0) onRefresh();
  }, []);

  useEffect(() => {
    setAddModal(false);
  }, [userList]);

  const onRefresh = () => {
    dispatch(userListRequest(token, metadata.pagination.currentPage, metadata.pagination.limit));
    dispatch(companyListRequest(token));
  };

  const handleChangePage = (page) => {
    if (page <= metadata.pagination.pageCount) {
      dispatch(userListRequest(token, page + 1, metadata.pagination.limit));
    }
  };

  const handleChangeRows = (rowsNumber) => {
    dispatch(
      userListRequest(
        token,
        metadata.pagination.currentPage,
        Number(rowsNumber.key)
      )
    );
  };

  // Função para atualizar os dados do usuário
  const handleUpdateUser = (userData) => {
    dispatch(userUpdateRequest(userData, user._id, token))
  }

  // Função para atualizar a senha do usuário
  // const handleUpdatePassword = (userPasswords) => {
  //   dispatch(userChangePasswordRequest(userPasswords, token, user._id))
  // }

  const handleUpdateUserAvatar = (dataRequest) => {
    dispatch(userAvatarUpdateRequest(dataRequest, user._id, token))
  }

  const loadOptions = async () => {
    const response = await api.axios.get(`/user`, {
      params: {
        search: query,
        limit: 10,
        fields: `name,_id`,
        sort: '-createdAt',
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    setOptions(response.data.items);
    console.log(response.data.items)
  };

  useEffect(() => {
    loadOptions();
  }, [query]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {tab === 0 && (
          <>
            <Grid container style={{ marginBottom: 12 }}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(e, value) => {
                      dispatch(userRequest(value._id, token, true))
                      setTab(1)
                      setQuery()
                    }}
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option.name.complete}
                    options={options}
                    loading={searchLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="dense"
                        label="Buscar usuário"
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {false ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <Toolbar
                  onRefresh={onRefresh}
                  actionText="Adicionar Usuário"
                  onAdd={() => setAddModal(true)}
                />
              </Grid>
            </Grid>

            <UsersTable
              onChangeRows={handleChangeRows}
              onChangePage={handleChangePage}
              metadata={metadata}
              users={userList}
              handleOpenModal={(userId) => {
                dispatch(userRequest(userId, token, true))
                setTab(1)
              }}
            />
          </>
        )}
        {tab === 1 && (
          <User
            loading={loading}
            user={user}
            companies={companyList}
            // passwordModal={passwordModal}
            handleSubmitData={handleUpdateUser}
            handleUpdatePassword={() => { }}
            handleUpdateUserAvatar={(dataRequest) => handleUpdateUserAvatar(dataRequest)}
            handleBack={() => setTab(0)}
          />
        )}
        {/* <EditUserModal
          active={modal}
          onClose={() => dispatch(handleCloseModal())}
          user={user}
          token={token}
        /> */}
        <AddUserModal
          loading={loading}
          companies={companyList}
          active={addModal}
          onClose={() => setAddModal(false)}
          addUser={(user) =>
            dispatch(
              userAddRequest({ ...user }, localStorage.getItem('@Matrix:token'))
            )
          }
        />
      </div>
    </div>
  );
}
