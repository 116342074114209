import produce from 'immer';

const INITIAL_STATE = {
  user: {
    name: {
      first: '...',
      last: '...',
      complete: '...'
    },
    type: 'user',
  },
  editUser: null,
  loading: true,
  error: null,
  userList: null,
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 10
    },
  },
  modal: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/USER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/USER_SUCCESS': {
        draft.user = action.dataRequest;
        draft.loading = false;
        break;
      }
      case '@user/EDIT_SUCCESST': {
        draft.editUser = action.dataRequest;
        draft.loading = false;
        draft.modal = true
        break;
      }
      case '@user/AVATAR_UPDATE_SUCCESS': {
        draft.editUser.photo.url = action.payload.url
        const findIndex = draft.userList.findIndex(user => user._id === action.payload.userId)
        if (findIndex >= 0) {
          draft.userList[findIndex].photo.url = action.payload.url
        }
        break
      }
      case '@user/EDIT_SUCCESS': {
        const userIndex = draft.userList.findIndex(u => u._id === action.dataRequest._id)
        if (userIndex >= 0) {
          draft.userList[userIndex] = action.dataRequest
        } else if (draft.user._id == action.dataRequest._id) {
          draft.user = action.dataRequest
        }
        draft.editUser = action.dataRequest;
        draft.loading = false;
        // draft.modal = false
        break;
      }
      case '@user/USER_FAILED': {
        draft.error = action.error;
        draft.loading = false;
        break;
      }
      case '@user/USER_LIST_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@user/USER_LIST_SUCCESS': {
        draft.userList = action.dataRequest.items;
        draft.metadata = action.dataRequest.metadata;
        draft.loading = false;
        break;
      }
      case '@user/ADD_USER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/USER_ADD_SUCCESS': {
        const { dataRequest } = action;
        draft.userList = [dataRequest, ...draft.userList];
        draft.loading = false;
        break;
      }
      case '@modal/OPEN': {
        draft.modal = true;
        break;
      }
      case '@modal/CLOSE': {
        draft.modal = false;
        break;
      }
      default:
        return state;
    }
  });
}
