export function inputRequest(inputId, token) {
  return {
    type: '@input/REQUEST',
    payload: { inputId, token }
  }
}

export function inputSuccess(dataRequest) {
  return {
    type: '@input/SUCCESS',
    payload: { dataRequest }
  }
}

export function inputListRequest(storeId, token, page = 1, rows = 35) {
  return {
    type: '@input/LIST_REQUEST',
    payload: { storeId, token, page, rows }
  }
}

export function inputListSuccess(dataRequest) {
  return {
    type: '@input/LIST_SUCCESS',
    payload: { dataRequest }
  }
}

export function inputFailed(dataRequest) {
  return {
    type: '@input/FAILED',
    payload: { dataRequest }
  }
}

export function inputAddRequest(dataInput, token) {
  return {
    type: '@input/ADD_REQUEST',
    payload: { dataInput, token }
  }
}

export function inputAddSuccess(dataRequest) {
  return {
    type: '@input/ADD_SUCCESS',
    payload: { dataRequest }
  }
}

export function inputUpdateRequest(inputId, dataInput, token, store) {
  return {
    type: '@input/UPDATE_REQUEST',
    payload: { inputId, dataInput, token, store }
  }
}

export function inputUpdateSuccess(dataRequest) {
  return {
    type: '@input/UPDATE_SUCCESS',
    payload: { dataRequest }
  }
}

export function getInputMonthSuccess(data) {
  return {
    type: '@input/GET_INPUT_MONTH_SUCCESS',
    payload: { data }
  }
}

export function handleOpenModal() {
  return {
    type: '@modal/INPUT_OPEN'
  }
}

export function handleCloseModal() {
  return {
    type: '@modal/INPUT_CLOSE'
  }
}

export function inputDeleteRequest(inputId, token) {
  return {
    type: '@input/DELETE_REQUEST',
    payload: { inputId, token }
  }
}

export function inputDeleteSuccess() {
  return {
    type: '@input/DELETE_SUCCESS',
  }
}
