import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '../../../repositories';
import { toast } from 'react-toastify';

import {
  storeListSuccess,
  storeAddSuccess,
  storeUpdateSuccess,
  storeAvatarUpdateSuccess,
  storeSuccess,
  unlinkUserSuccess,
  linkUserSuccess,
  updateUserLinkSuccess
} from './actions';

import { requestFailed } from '../error/action';

export function* getStore({ payload }) {
  try {
    const { storeId, token } = payload;
    const response = yield call(api.axios.get, `/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(storeSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* storeList({ payload }) {
  try {
    const { token, page, rows } = payload;
    const response = yield call(api.axios.get, `/store/?limit=${rows}&page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(storeListSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* storeAdd({ payload }) {
  try {
    const { dataStore, token } = payload;
    const response = yield call(api.axios.post, `/store`, dataStore, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(storeAddSuccess({
      id: response.data,
      ...dataStore
    }));
    toast.success('Loja adicionada com sucesso!');
    // yield put(inputUpdate(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* storeUpdate({ payload }) {
  try {
    const { dataStore, storeId, token } = payload;
    const response = yield call(api.axios.put, `/store/${storeId}`, dataStore, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // yield put(storeListRequest(token));
    console.log(response.data)
    yield put(storeUpdateSuccess(response.data));
    toast.success('Informações atualizadas com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* storeAvatarUpdate({ payload }) {
  try {
    const { dataRequest, token, storeId } = payload
    const response = yield call(api.axios.post, `/store/${storeId}/upload`, dataRequest, {
      headers: { Authorization: `Bearer ${token}` }
    })

    toast.success('Avatar atualizado com sucesso.')
    yield put(storeAvatarUpdateSuccess(response.data.url))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* updateUserLink({ payload }) {
  try {
    const { dataUser, userId, token } = payload;
    const response = yield call(
      api.axios.put,
      `/user/${userId}/linkStore`,
      dataUser,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data)
    yield put(updateUserLinkSuccess(dataUser, userId))
    toast.success('Usuário alterado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* linkUser({ payload }) {
  try {
    const { dataUser, userId, token } = payload;
    const response = yield call(
      api.axios.post,
      `/user/${userId}/linkStore`,
      {
        storeId: dataUser.storeId,
        type: dataUser.type,
        code: dataUser.code
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(linkUserSuccess(response.data));
    toast.success('Usuário vinculado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export function* unlinkUser({ payload }) {
  try {
    const { storeId, userId, token } = payload;
    yield call(
      api.axios.post,
      `/user/${userId}/unlinkStore`,
      { storeId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(unlinkUserSuccess(userId));
    toast.success('Usuário desvinculado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      );
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      );
    }
  }
}

export default all([
  takeLatest('@store/UPDATE_REQUEST', storeUpdate),
  takeLatest('@store/REQUEST', getStore),
  takeLatest('@store/LIST_REQUEST', storeList),
  takeLatest('@store/ADD_REQUEST', storeAdd),
  takeLatest('@store/UPDATE_USER_LINK_REQUEST', updateUserLink),
  takeLatest('@store/LINK_USER_REQUEST', linkUser),
  takeLatest('@store/UNLINK_USER_REQUEST', unlinkUser),
  takeLatest('@store/AVATAR_UPDATE_REQUEST', storeAvatarUpdate),
]);
