import React, { useState, useEffect } from 'react'

// Libs 
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

//Components
import { Users, StoreSettings, Report, Inputs, IntegrationSettings, Goals } from './components'

// Redux Actions
import { storeRequest, storeUpdateRequest, storeAvatarUpdateRequest } from '../../store/modules/store/actions'
import { inputListSuccess } from '../../store/modules/input/actions'
import { goalListReset } from '../../store/modules/goal/actions'


// Styles
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Store = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: storeId } = useParams()

  const token = localStorage.getItem('@Matrix:token')

  const store = useSelector(state => state.store.store)
  const storeLoading = useSelector(state => state.store.loading)

  const userList = useSelector(state => state.user.userList)

  const companyList = useSelector(state => state.company.companyList);

  // // State
  const [tab, setTab] = useState('storeSettings')

  // Tabs
  const tabs = [
    { value: 'storeSettings', label: 'Loja' },
    { value: 'users', label: 'Usuários' },
    { value: 'inputs', label: 'Lançamentos' },
    { value: 'report', label: 'Relatório' },
    { value: 'goals', label: 'Metas' },
    { value: 'integration', label: 'Integração' },
  ]

  // Função para atualizar os dados da loja
  const handleUpdateStore = (storeData) => {
    dispatch(storeUpdateRequest(storeData, store ? store._id : storeId, token))
  }

  const handleUpdateStoreAvatar = (dataRequest) => {
    dispatch(storeAvatarUpdateRequest(dataRequest, store ? store._id : storeId, token))
  }

  useEffect(() => {
    if (!store) {
      dispatch(storeRequest(storeId, token))
    }

    return () => {
      dispatch(inputListSuccess(null))
      dispatch(goalListReset())
    }
  }, [])

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        onChange={(e, value) => setTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'storeSettings' && (
          store &&
          <StoreSettings
            loading={storeLoading}
            handleSubmitData={handleUpdateStore}
            handleUpdateStoreAvatar={handleUpdateStoreAvatar}
            store={store}
            companies={companyList}
          />)}
        {tab === 'users' && (
          <Users
            store={store}
            userList={userList}
          />
        )}
        {tab === 'report' && (
          <Report />
        )}
        {tab === 'inputs' && (
          <Inputs />
        )}
        {tab === 'integration' && (
          <IntegrationSettings
            storeIntegrationData={store.integration}
            storeId={store._id}
          />
        )}
        {tab === 'goals' && (
          <Goals />
        )}
      </div>
    </div>
  )
}

export default Store
