export function reportRequest(storeId, token, range) {
  return {
    type: '@report/LIST_REQUEST',
    payload: { storeId, token, range }
  }
}

export function reportSuccess(dataRequest) {
  return {
    type: '@report/LIST_SUCCESS',
    payload: { dataRequest }
  }
}

export function monthRequest(storeId, token) {
  return {
    type: '@report/MONTH_REQUEST',
    payload: { storeId, token }
  }
}

export function monthSuccess(dataRequest) {
  return {
    type: '@report/MONTH_SUCCESS',
    payload: { dataRequest }
  }
}

export function storesReportRequest(userId, token) {
  return {
    type: '@report/STORES_REQUEST',
    payload: { userId, token }
  }
}

export function storesReportSuccess(dataRequest) {
  return {
    type: '@report/STORES_SUCCESS',
    payload: { dataRequest }
  }
}