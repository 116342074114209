import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import LoadingOverlay from 'react-loading-overlay';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
// import moment from "moment";

import jwt_decode from 'jwt-decode';
import { storeListRequest } from '../../store/modules/store/actions';
import { userRequest, userListRequest } from '../../store/modules/user/actions';
import { companyListRequest } from '../../store/modules/company/actions';

import { Sidebar, Topbar, Footer } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  inner: {
    minWidth: 350,
    minHeight: 250,
    marginLeft: 45,
  },
  inputForm: {
    width: 100,
    minWidth: 150,
    marginBottom: 15,
    marginRight: 5,
  },
}));

export default function Main({ children }) {
  const dispatch = useDispatch();

  // user: state.repositories.user,
  // loading: state.repositories.loading,
  // error: state.repositories.error,
  // inputList: state.repositories.inputList,
  // userList: state.repositories.userList

  const user = useSelector((state) => state.user.user);
  const storeLoading = useSelector((state) => state.store.loading);
  const userLoading = useSelector((state) => state.user.loading);
  const reportLoading = useSelector((state) => state.report.loading);
  const companyLoading = useSelector((state) => state.company.loading);

  const error = useSelector((state) => state.error.error);
  const storeList = useSelector((state) => state.store.storeList);
  const userList = useSelector((state) => state.user.userList);
  const companyList = useSelector((state) => state.company.companyList);

  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const token = localStorage.getItem('@Matrix:token');

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    if (error) {
      console.error(error)

      const errorMessage = error.error?.friendlyMsg
        ? error.error.friendlyMsg
        : 'Ocorreu um erro inesperado!'

      toast.error(errorMessage, { toastId: 'main-error' })
    }
  }, [error]);

  const handleSidebarOpen = async () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const logoff = () => {
    localStorage.removeItem('@Matrix:token');
    history.push('/login');
  };
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  // Verifica se user está logado
  useEffect(() => {
    if (!user._id) {
      if (token) {
        const user = jwt_decode(token);
        dispatch(userRequest(user.id, token));
      } else {
        history.push('/login');
      }
    }
    if (!storeList && !userList && !companyList) {
      dispatch(storeListRequest(token));
      dispatch(userListRequest(token));
      dispatch(companyListRequest(token));
    }
  }, [dispatch, history, storeList, companyList, token, user._id, userList]);

  return (
    <LoadingOverlay active={storeLoading || userLoading || reportLoading || companyLoading} spinner>
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop,
        })}
      >
        <Topbar onSidebarOpen={handleSidebarOpen} onLogoff={logoff} />
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          onOpen={handleSidebarOpen}
          variant={isDesktop ? 'persistent' : 'temporary'}
          user={user}
          onLogoff={logoff}
        />
        <main className={classes.content}>
          {children}
          <Footer />
        </main>
      </div>
    </LoadingOverlay>
  );
}

Main.propTypes = {
  children: PropTypes.node,
};
