import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  Dashboard as DashboardView,
  StoreList as StoreListView,
  UserList as UserListView,
  // Account as AccountView,
  // Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Store as StoreView,
  // Goals as GoalsView,
  // Reports as ReportsView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={StoreListView}
        exact
        layout={MainLayout}
        path="/stores"
      />
      <RouteWithLayout
        component={StoreView}
        exact
        layout={MainLayout}
        path="/store/:id"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      {/* <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      /> */}
      {/* <RouteWithLayout
        component={GoalsView}
        exact
        layout={MainLayout}
        path="/goals"
      /> */}
      {/* <RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        path="/report"
      /> */}
      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/register"
      /> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
