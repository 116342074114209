import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Utils
import { cepMask, cnpjMask, phoneMask } from "../../../../utils/inputMasks";

// Material UI
import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid'
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'

import AvatarInput from '../AvatarInput'

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },

  margin: {
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(2),

  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))


const StoreSettings = ({
  className,
  loading,
  store,
  companies,
  handleSubmitData,
  handleUpdateStoreAvatar,
  ...rest
}) => {
  const classes = useStyles()

  const [data, setData] = useState({
    avatar: '',
    name: '',
    phone: '',
    cnpj: '',
    city: '',
    address: '',
    cep: '',
    masterCode: '',
    companyId: '',
  })

  // Função para popular o estado de data
  const handleChangeData = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  // Função para enviar a requisição
  const submitData = () => {
    handleSubmitData({
      ...data,
      phone: data.phone.replace(/[^a-zA-Z0-9]/g, "",),
      cep: data.cep.replace(/[^a-zA-Z0-9]/g, "",),
      cnpj: data.cnpj.replace(/[^a-zA-Z0-9]/g, "",),
    })
  }

  useEffect(() => {
    setData({
      avatar: store.photo.url,
      name: store.name,
      phone: store.phone,
      cnpj: store.cnpj,
      city: store.city,
      address: store.address,
      cep: store.cep,
      masterCode: store.masterCode,
      companyId: store.companyId,
    })
  }, [store])

  return (
    <Grid spacing={2} container className={classes.container}>
      <Grid item xs={12} md={3} >
        <AvatarInput
          image={store && store.photo.url}
          title={store && store.name}
          subTitle={store && store.email}
          handleSubmitImage={(dataRequest) => handleUpdateStoreAvatar(dataRequest)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete='off'
            noValidate
          >
            <CardHeader
              subheader='Edite aqui as informações da loja'
              title='Loja'
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Nome'
                    margin='dense'
                    name='name'
                    onChange={handleChangeData}
                    required
                    value={data.name}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Telefone'
                    margin='dense'
                    name='phone'
                    onChange={handleChangeData}
                    onFocus={(event) => event.target.select()}
                    value={data.phone}
                    InputProps={{
                      inputComponent: phoneMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cep'
                    margin='dense'
                    name='cep'
                    onChange={handleChangeData}
                    required
                    value={data.cep}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cepMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cidade'
                    margin='dense'
                    name='city'
                    onChange={handleChangeData}
                    required
                    value={data.city}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Endereço'
                    margin='dense'
                    name='address'
                    onChange={handleChangeData}
                    required
                    value={data.address}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='CNPJ'
                    margin='dense'
                    name='cnpj'
                    onChange={handleChangeData}
                    required
                    value={data.cnpj}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cnpjMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Senha mestre'
                    margin='dense'
                    name='masterCode'
                    onChange={handleChangeData}
                    required
                    value={data.masterCode}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    variant='outlined'
                    margin='dense'
                  >
                    <InputLabel id="companyId-label">Empresa *</InputLabel>
                    <Select
                      labelWidth={65}
                      name='companyId'
                      labelId='companyId-label'
                      label='Empresa'
                      onChange={handleChangeData}
                      required
                      value={data.companyId}
                      variant='outlined'
                    >
                      {
                        companies && companies.map((company) => (
                          <MenuItem value={company._id} key={company._id}>{company.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={() => submitData()}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: '#fff' }} />
                ) : (
                    'Salvar'
                  )}
              </Button>
            </CardActions>
          </form>
        </Card >
      </Grid>
    </Grid>
  )
}

StoreSettings.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  store: PropTypes.object,
  handleSubmitData: PropTypes.func,
  handleUpdateStoreAvatar: PropTypes.func,
}

export default StoreSettings
