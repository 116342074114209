import produce from 'immer'

const INITIAL_STATE = {
  loading: false
}

export default function store(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@integration/REQUEST': {
        draft.loading = true
        break
      }
      case '@integration/SUCCESS': {
        draft.loading = false
        break
      }
      case '@integration/DISCONNECT_REQUEST': {
        draft.loading = true
        break
      }
      case '@integration/DISCONNECT_SUCCESS': {
        draft.loading = false
        break
      }
      case '@store/SUCCESS': {
        draft.loading = false
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
