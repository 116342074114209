import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const UsersTable = ({ className, users, onChangeRows, onChangePage, metadata, handleOpenModal, ...rest }) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Loja</TableCell>
                  <TableCell>Data de cadastro</TableCell>
                  <TableCell>Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.length > 0 ? (
                  users.slice(0, metadata.pagination.limit).map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user._id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOpenModal(user._id)}
                    >
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={user.photo.url}
                          />
                          <Typography variant="body1">
                            {user.name.complete}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {user.phone?.replace(
                          /(\d{2})(\d{5})(\d{4})/g,
                          '($1) $2-$3'
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" style={{overflow: 'hidden'}}>
                          {user.stores.length > 0 ? user.stores.map(store => store.storeId.name).join(', ') : 'Nenhuma loja cadastrada'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {moment(user.stores.length > 0 ? user.stores[0].joinedAt : 'Nenhuma loja cadastrada').format('DD/MM/YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {
                          user.stores.length > 0 ? {
                            seller: 'Vendendor',
                            manager: 'Gerente',
                            cashier: 'Caixa',
                            owner: 'Dono',
                          }[user.stores[0].type] : '-'
                        }
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                    <TableRow>
                      <TableCell colSpan="5" style={{ textAlign: 'center' }}>
                        Sem usuários cadastrados.
                    </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metadata ? metadata.pagination.totalCount : 0}
          onChangePage={(_event, page) => onChangePage(page)}
          onChangeRowsPerPage={(_event, rows) => onChangeRows(rows)}
          page={metadata ? metadata.pagination.currentPage - 1 : 0}
          rowsPerPage={metadata.pagination.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
};

export default UsersTable;
