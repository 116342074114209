import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from 'material-ui-confirm'

import DayPicker, { DateUtils } from 'react-day-picker'
import dayPickerBR from '../../../../../../../../common/DayPicker-pt-BR'


import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import { formatPrice } from '../../../../../../../../utils/format'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  divider: {
    borderLeft: '2px solid #CCC',
    padding: theme.spacing(1, 2)
  },
  column: {
    flexBasis: '50%',
    marginTop: '80px'
  }
}))

export default function EditUserGoalModal({
  modal,
  currentMonthGoal,
  handleLinkUserGoal,
  user,
  onClose,
  ...rest
}) {
  const classes = useStyles()
  const confirm = useConfirm()
  const [days, setDays] = useState([])

  useEffect(() => {
    if (currentMonthGoal && user) {
      const workingDays = currentMonthGoal.days
        .filter(day => day.working && day.users.map(user => String(user)).includes(user._id))
        .map(day => new Date(day.date))
      if (workingDays.length > 0) {
        setDays(workingDays)
      } else {
        setDays(currentMonthGoal.days
          .filter(day => day.working)
          .map(day => new Date(day.date)))
      }
    }
  }, [currentMonthGoal, user])

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={modal}
        onClose={onClose}
      >
        <DialogTitle id='form-dialog-title'>Editar dias</DialogTitle>
        <DialogContent>
          {currentMonthGoal ? (
            <Grid container spacing={1} className={classes.margin}>
              <Grid item xs>
                <DayPicker
                  month={new Date(currentMonthGoal.month)}
                  canChangeMonth={false}
                  months={dayPickerBR.months}
                  weekdaysLong={dayPickerBR.weekdaysLong}
                  weekdaysShort={dayPickerBR.weekdaysShort}
                  firstDayOfWeek={0}
                  labels={dayPickerBR.labels}
                  modifiers={{
                    working: day =>
                      currentMonthGoal.days.filter(d => d.working)
                        .map(dayM => dayM.date)
                        .includes(day.toISOString())
                  }}
                  selectedDays={days}
                  modifiersStyles={{
                    working: {
                      color: '#8e44ad',
                      fontWeight: 'bold'
                    }
                  }}
                  onDayClick={(day, { selected }) => {
                    if (
                      currentMonthGoal.days.filter(d => d.working)
                        .map(dayM => dayM.date)
                        .includes(day.toISOString())
                    ) {
                      if (selected) {
                        // deselecionar
                        const selectedIndex = days.findIndex(selectedDay =>
                          DateUtils.isSameDay(selectedDay, day)
                        )
                        days.splice(selectedIndex, 1)
                        setDays([...days])
                      } else {
                        // selecionar
                        // days.push(day.toISOString())
                        setDays([...days, day])
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                <div className={clsx(classes.column, classes.divider)}>
                  <Typography>
                    Selecione os dias em que <b>{user ? user.name.first : '...'}</b> irá trabalhar.
                  </Typography>
                  <Typography>
                    <b>Atenção: </b>A meta será proporcional aos dias trabalhados.
                  </Typography>
                  {currentMonthGoal && days && currentMonthGoal.monthGoals.map(g => {
                    const ISOList = days.map(day => day.toISOString())
                    const goingCoef = currentMonthGoal.days.filter(day => ISOList.includes(day.date)).reduce((acc, day) => {
                      acc += day.goal
                      return acc
                    }, 0) / currentMonthGoal.mainGoals.total

                    const preCom = g.value * goingCoef * g.commission
                    const bonus = currentMonthGoal.bonusPercentage ? g.value * goingCoef * g.bonus : g.bonus
                    const dsr = currentMonthGoal.dsr > 0 ? ((preCom / currentMonthGoal.workingDays) * currentMonthGoal.dsr) : 0
                    const base = currentMonthGoal.salary.directCommission ? -currentMonthGoal.salary.base : 0

                    const commission = preCom + dsr + bonus + base

                    return (
                      <div key={g.name}>
                        <Typography variant='h5'>
                          {g.name}
                        </Typography>
                        <Typography>
                          {formatPrice(g.value * goingCoef)} <b>{formatPrice(commission <= 0 ? 0 : commission)}</b>
                        </Typography>
                      </div>
                    )
                  })}
                </div>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Button
                    onClick={() => {
                      confirm({
                        title: 'Remover vendedor da meta',
                        description: `Você tem certeza que deseja remover ${user.name.first} da meta?`,
                        confirmationText: 'Sim',
                        cancellationText: 'Não'
                      })
                        .then(() => {
                          handleLinkUserGoal({ days: [], userId: user._id }, currentMonthGoal._id)
                        })
                        .catch(() => { })
                    }}
                    style={{ color: '#e74c3c' }}
                  >
                    Remover da meta
                  </Button>
                  <Button
                    onClick={() => setDays(currentMonthGoal.days
                      .filter(day => day.working)
                      .map(day => new Date(day.date)))}
                  >
                    Todos os dias
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
              <DialogContentText>
                Carregando...
              </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const submit = () => handleLinkUserGoal(
                { days: days.map(d => d.toISOString()), userId: user._id },
                currentMonthGoal._id
              )

              if (days.length < currentMonthGoal.days.filter(d => d.working).length) {
                confirm({
                  title: 'Salvar',
                  description: `Atenção: A meta de ${user.name.first} será proporcional aos dias trabalhados.`,
                  confirmationText: 'Ok',
                  cancellationText: 'Cancelar'
                })
                  .then(() => submit())
                  .catch(() => { })
              } else {
                submit()
              }
            }}
            type='submit'
            color='primary'
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
