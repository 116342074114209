import produce from 'immer'

const INITIAL_STATE = {
  reportsList: null,
  storesReport: null,
  monthReport: null,
  currentMonthReportLoaded: false,
  lastUpdate: null,
  loading: false
}

export default function report(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@report/MONTH_REQUEST': {
        draft.monthReport = null
        draft.loading = true
        break
      }
      case '@report/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@report/LIST_SUCCESS': {
        draft.reportsList = action.payload.dataRequest
        draft.loading = false
        break
      }
      case '@report/MONTH_SUCCESS': {
        draft.monthReport = action.payload.dataRequest
        draft.loading = false
        draft.currentMonthReportLoaded = true
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@input/DELETE_SUCCESS': {
        draft.monthReport = null
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@report/STORES_REQUEST': {
        draft.loading = true
        break
      }
      case '@report/STORES_SUCCESS': {
        draft.storesReport = action.payload.dataRequest
        draft.loading = false
        break
      }
      case '@main/CLEAR_REDUX': {
        draft.monthReport = null
        draft.reportsList = null
        break
      }
      case 'requestFailed': {
        draft.loading = false
        draft.currentMonthReportLoaded = true
        break
      }
      default:
        return state
    }
  })
}
