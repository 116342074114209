import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { Tabs, Tab, Divider, colors, Grid } from '@material-ui/core'
import moment from 'moment'

import CalendarIcon from '@material-ui/icons/CalendarToday'

import Header from '../../../../../../components/Header'
import { goalRequest, goalDeleteRequest, goalEditDayRequest, goalEditMainGoalsRequest } from '../../../../../../store/modules/goal/actions'
import { userListRequest } from '../../../../../../store/modules/user/actions'

import ValueCard from 'components/ValueCard'
import { formatPrice } from '../../../../../../utils/format'
import Goals from './components/Goals'
import EditGoalModal from './components/EditGoalModal'
import Sellers from './components/Sellers'
import Days from './components/Days'

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(4)
  },
  tabs: {
    marginTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(1)
  }
}))

const Goal = ({ match, history, id, handleBack }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const goalList = useSelector(state => state.goal.goalList)
  const requestedGoal = useSelector(state => state.goal.goal)
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const userList = useSelector(state => state.user.userList)
  const userListMetadata = useSelector(state => state.user.metadata)
  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)

  const token = window.localStorage.getItem('@Matrix:token')

  const [goal, setGoal] = useState()
  const [users, setUsers] = useState([])
  const [newUser, setNewUser] = useState()

  const [tab, setTab] = useState('goals')
  const [visibleEditGoal, setVisibleEditGoal] = useState(false)

  useEffect(() => {
    if (goal) {
      const usersWorking = goal.usersWorking.map(u => String(u.userId._id))
      setUsers(store.users.filter(u => {
        const thisStore = u.stores.find(s => s.storeId._id === store._id)
        return thisStore ? !usersWorking.includes(String(u._id)) && u._id !== user._id && thisStore.type === 'seller' : false
      }))
    }
  }, [goal, userList])

  useEffect(() => {
    const listFind = goalList.find(g => g._id === id)
    if (listFind) {
      setGoal(listFind)
    } else if (requestedGoal && requestedGoal._id === id) {
      setGoal(requestedGoal)
    } else if (currentGoal) {
      if (currentGoal && currentGoal._id === id) {
        setGoal(currentGoal)
      }
    } else {
      dispatch(goalRequest(id, token))
    }
  }, [goalList, requestedGoal])

  useEffect(() => {
    if (goal) setVisibleEditGoal(false)
  }, [goal])

  const tabs = [
    { value: 'goals', label: 'Metas' },
    { value: 'sellers', label: 'Vendedores' },
    { value: 'days', label: 'Dias' }
  ]

  const handleDeleteGoal = () => {
    confirm({
      title: `Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`,
      description: 'Tem certeza que deseja apagar a meta? Os lançamentos desse mês serão mantidos.',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        handleBack()
        dispatch(
          goalDeleteRequest(goal._id, window.localStorage.getItem('@Matrix:token'))
        )
      })
      .catch(() => { })
  }

  return (
    <div className={classes.root}>
      <Header
        data={goal}
        goal
        handleGoBack={() => handleBack()}
        title='Metas'
        actionText="Apagar"
        subtitle={`Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`}
        value={`${goal ? formatPrice(goal.mainGoals.total) : 0}`}
        handleDelete={handleDeleteGoal}
        secondaryText="Editar"
        handleSecondary={() => setVisibleEditGoal(true)}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Meta da loja'
            value={`${goal ? formatPrice(goal.mainGoals.total) : 0}`}
            color='#682AC7'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Ticket médio'
            value={`${goal ? formatPrice(goal.mainGoals.ticket) : 0}`}
            editable={true}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Preço médio'
            value={`${goal ? formatPrice(goal.mainGoals.price) : 0}`}
            editable={true}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='DSR'
            value={goal && goal.dsr}
            icon={<CalendarIcon />}
            editable={true}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
      </Grid>
      <Tabs
        className={classes.tabs}
        onChange={(e, value) => setTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        {tab === 'goals' && <Goals goal={goal} />}
        {tab === 'sellers' && <Sellers goal={goal} users={users} newUser={user} newUser={newUser} setNewUser={setNewUser} />}
        {tab === 'days' && <Days goal={goal} onEditDay={(day) => dispatch(goalEditDayRequest(goal._id, day._id, day, token))} />}
      </div>

      <EditGoalModal
        active={visibleEditGoal}
        onClose={() => setVisibleEditGoal(false)}
        goal={goal}
        onSave={data => dispatch(goalEditMainGoalsRequest(goal._id, data, token))}
      />
    </div>
  )
}

Goal.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default Goal
