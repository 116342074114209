// export function inputRequest(input, token) {
//   return {
//     type: '@store/REQUEST',
//     payload: { input, token }
//   };
// }

// export function inputSuccess(dataRequest) {
//   return {
//     type: 'inputSuccess',
//     payload: { dataRequest }
//   };
// }

export function storeRequest(storeId, token) {
  return {
    type: '@store/REQUEST',
    payload: { storeId, token }
  }
}

export function storeSuccess(dataRequest) {
  return {
    type: '@store/SUCCESS',
    payload: { dataRequest }
  }
}

export function storeListRequest(token, page = 1, rows = 10) {
  return {
    type: '@store/LIST_REQUEST',
    payload: { token, page, rows },
  };
}

export function storeListSuccess(dataRequest) {
  return {
    type: '@store/LIST_SUCCESS',
    payload: { dataRequest },
  };
}

// export function inputFailed(dataRequest) {
//   return {
//     type: 'inputFailed',
//     dataRequest,
//   };
// }

export function storeAddRequest(dataStore, token) {
  return {
    type: '@store/ADD_REQUEST',
    payload: { dataStore, token },
  };
}

export function storeAddSuccess(dataRequest) {
  return {
    type: '@store/ADD_SUCCESS',
    payload: { dataRequest },
  };
}

export function storeUpdateRequest(dataStore, storeId, token) {
  return {
    type: '@store/UPDATE_REQUEST',
    payload: { dataStore, storeId, token },
  };
}

export function storeUpdateSuccess(dataRequest) {
  return {
    type: '@store/UPDATE_SUCCESS',
    payload: { dataRequest }
  };
}

export function storeAvatarUpdateRequest(dataRequest, storeId, token) {
  return {
    type: '@store/AVATAR_UPDATE_REQUEST',
    payload: { dataRequest, storeId, token }
  }
}

export function storeAvatarUpdateSuccess(url) {
  return {
    type: '@store/AVATAR_UPDATE_SUCCESS',
    payload: { url }
  }
}

export function updateUserLink(dataUser, userId, token) {
  return {
    type: '@store/UPDATE_USER_LINK_REQUEST',
    payload: { dataUser, userId, token },
  };
}

export function updateUserLinkSuccess(dataUser, userId) {
  return {
    type: '@store/UPDATE_USER_LINK_SUCCESS',
    payload: { dataUser, userId },
  };
}

export function linkUser(dataUser, userId, token) {
  return {
    type: '@store/LINK_USER_REQUEST',
    payload: { dataUser, userId, token },
  };
}

export function linkUserSuccess(dataUser) {
  return {
    type: '@store/LINK_USER_SUCCESS',
    payload: { dataUser },
  };
}

export function unlinkUser(storeId, userId, token) {
  return {
    type: '@store/UNLINK_USER_REQUEST',
    payload: { storeId, userId, token },
  };
}

export function unlinkUserSuccess(userId) {
  return {
    type: '@store/UNLINK_USER_SUCCESS',
    payload: { userId }
  };
}

// export function inputUpdate(dataRequest) {
//   return {
//     type: 'inputUpdate',
//     dataRequest,
//   };
// }

export function handleOpenModal() {
  return {
    type: '@modal/OPEN'
  }
}

export function handleCloseModal() {
  return {
    type: '@modal/CLOSE'
  }
}
