/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

// Libs
import { useForm, Controller } from "react-hook-form"
import axios from 'axios'

// Material UI
import { makeStyles } from "@material-ui/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';

// Utils
import { phoneMask, cnpjMask, cepMask } from '../../../utils/inputMasks';

const useStyles = makeStyles(theme => ({
  root: { minWidth: 650 },
  margin: {
    margin: theme.spacing(1)
  },
  buttonSuccess: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white"
    }
  }
}));

export default function AddStoreModal({
  active,
  onClose,
  addStore,
  loading,
  companies,
  ...rest
}) {


  const classes = useStyles();
  const { register, handleSubmit, errors, watch, setValue, setError, clearError, control } = useForm()

  const data = watch()

  const [searchCepLoading, setSearchCepLoading] = useState(false)

  const onSubmit = data => addStore({
    name: data.name,
    companyId: data.company,
    phone: data.phone.replace(/[^a-zA-Z0-9]/g, "",),
    cnpj: data.cnpj.replace(/[^a-zA-Z0-9]/g, "",),
    city: data.city,
    address: data.address,
    email: data.email,
    cep: data.cep.replace(/[^a-zA-Z0-9]/g, "",)
  })

  const renderError = (error) => errors[error] && (
    <FormHelperText id="component-error-text">
      {errors[error]?.isManual ? errors[error].types.message : 'Este campo é obrigatório'}
    </FormHelperText>
  )

  useEffect(() => {
    if (data.cep) {
      const filteredCep = data.cep.replace(/[^a-zA-Z0-9]/g, "",)
      if (filteredCep.length === 8) {
        setSearchCepLoading(true)
        axios.get(`https://viacep.com.br/ws/${filteredCep}/json/`).then(response => {
          if (!response.data.erro) {
            setValue('address', response.data.logradouro)
            setValue('city', response.data.localidade)
            setSearchCepLoading(false)
            clearError('cep')
          } else {
            setSearchCepLoading(false)
            setError('cep', {
              type: "manual",
              message: 'CEP não encontrado!'
            })
            setValue('address', '')
            setValue('city', '')
          }
        }).catch(_error => {
          setSearchCepLoading(false)
        })
      }
    }
  }, [data.cep])

  return (
    <div {...rest}>
      <Dialog
        open={active}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        className={classes.root}
        fullWidth
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle id="form-dialog-title">Adicionar Loja</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os dados abaixo para adicionar uma nova loja.
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.name}
                >
                  <InputLabel htmlFor="name">Nome</InputLabel>
                  <OutlinedInput
                    id="name"
                    name="name"
                    inputRef={register({ required: true })}
                    labelWidth={37}
                    fullWidth
                  />
                  {renderError('name')}
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.phone}
                >
                  <InputLabel htmlFor="phone">Telefone</InputLabel>
                  <OutlinedInput
                    id="phone"
                    name="phone"
                    inputRef={register({ required: true })}
                    labelWidth={55}
                    inputComponent={phoneMask}
                    value={data.phone}
                  />
                  {renderError('phone')}
                </FormControl>
              </Grid>
            </Grid>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.company}
            >
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <Controller
                control={control}
                rules={{ required: true }}
                name="company"
                defaultValue=""
                as={
                  <Select labelWidth={55}>
                    {
                      companies && companies.map((company) => (
                        <MenuItem value={company._id} key={company._id}>{company.name}</MenuItem>
                      ))
                    }
                  </Select>
                }
              />
              {renderError('company')}
            </FormControl>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.email}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                name="email"
                inputRef={register}
                labelWidth={34}
              />
              {renderError('email')}
            </FormControl>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.cnpj}
                >
                  <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
                  <OutlinedInput
                    id="cnpj"
                    name="cnpj"
                    inputRef={register({ required: true })}
                    labelWidth={34}
                    inputComponent={cnpjMask}
                    value={data.cnpj}
                  />
                  {renderError('cnpj')}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.cep}
                >
                  <InputLabel htmlFor="cep">CEP</InputLabel>
                  <OutlinedInput
                    id="cep"
                    name="cep"
                    inputRef={register({ required: true })}
                    value={data.cep}
                    labelWidth={25}
                    inputComponent={cepMask}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        {searchCepLoading && <CircularProgress size={14} />}
                      </InputAdornment>
                    }
                  />
                  {renderError('cep')}
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  error={!!errors.city}
                >
                  <InputLabel htmlFor="cep">Cidade</InputLabel>
                  <OutlinedInput
                    id="city"
                    name="city"
                    label="Cidade"
                    inputRef={register({ required: true })}
                    value={data.city ? data.city : ''}
                    labelWidth={42}
                    fullWidth
                  />
                  {renderError('city')}
                </FormControl>
              </Grid>
            </Grid>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
              error={!!errors.address}
            >
              <InputLabel htmlFor="address">Endereço</InputLabel>
              <OutlinedInput
                id="address"
                name="address"
                inputRef={register({ required: true })}
                labelWidth={58}
              />
              {renderError('address')}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                  "Adicionar"
                )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

AddStoreModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  addInput: PropTypes.func,
  inputList: PropTypes.array,
  userList: PropTypes.array,
  companies: PropTypes.array,
  loading: PropTypes.bool,
};
