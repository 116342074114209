export function goalRequest(goalId, token) {
  return {
    type: '@goal/GOAL_REQUEST',
    payload: { goalId, token }
  }
}

export function goalListRequest(storeId, token, page = 1, limit = 10) {
  return {
    type: '@goal/LIST_REQUEST',
    payload: { storeId, token, page, limit }
  }
}

export function goalListSuccess(dataRequest) {
  return {
    type: '@goal/LIST_SUCCESS',
    payload: { dataRequest }
  }
}

export function goalAddRequest(goal, token) {
  return {
    type: '@goal/ADD_REQUEST',
    payload: { goal, token }
  }
}

export function goalAddSuccess(dataRequest) {
  return {
    type: '@goal/ADD_SUCCESS',
    payload: { dataRequest }
  }
}

export function goalFailed(dataRequest) {
  return {
    type: '@goal/FAILED',
    payload: { dataRequest }
  }
}

export function handleOpenModal() {
  return {
    type: '@modal/GOAL_OPEN'
  }
}

export function handleCloseModal() {
  return {
    type: '@modal/GOAL_CLOSE'
  }
}

export function currentGoalRequest(storeId, token) {
  return {
    type: '@goal/CURRENT_REQUEST',
    payload: { storeId, token }
  }
}

export function currentGoalSuccess(dataRequest) {
  return {
    type: '@goal/CURRENT_SUCCESS',
    payload: { dataRequest }
  }
}

export function currentGoalFailed() {
  return {
    type: '@goal/CURRENT_FAILED'
  }
}

export function monthGoalRequest(storeId, token, date) {
  return {
    type: '@goal/MONTH_REQUEST',
    payload: { storeId, token, date }
  }
}

export function monthGoalSuccess(dataRequest) {
  return {
    type: '@goal/MONTH_SUCCESS',
    payload: { dataRequest }
  }
}

export function goalUpdateUsers(data, goalId, token) {
  console.log(data)
  return {
    type: '@goal/LINK_USER',
    payload: { data, goalId, token }
  }
}

export function goalDeleteRequest(goalId, token) {
  return {
    type: '@goal/DELETE_REQUEST',
    payload: { goalId, token }
  }
}

export function goalEditDayRequest(goalId, dayId, data, token) {
  return {
    type: '@goal/EDIT_DAY_REQUEST',
    payload: { goalId, dayId, data, token }
  }
}

export function goalEditMainGoalsRequest(goalId, data, token) {
  return {
    type: '@goal/EDIT_MAINGOALS_REQUEST',
    payload: { goalId, data, token }
  }
}

export function goalListReset() {
  return {
    type: '@goal/RESET'
  }
}