import React, { useState } from "react";

// Libs
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from 'react-redux'
import clsx from "clsx";
import PropTypes from "prop-types";
import api from '../../../../repositories/api';

// Material UI
import { makeStyles } from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

// Components
import EditUserModal from '../EditUserModal'
import LinkUserModal from '../LinkUserModal'

// Redux
import { updateUserLink, unlinkUser } from '../../../../store/modules/store/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const Users = ({
  store,
  userList,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [linkUserModal, setLinkUserModal] = useState(false)
  const [user, setUser] = useState(false)
  const [searchList, setSearchList] = useState(null)

  const token = localStorage.getItem('@Matrix:token')

  const handleSearch = async () => {
    const response = await api.get(api.axios.get, `/user?search=${'proft'}`, {
      headers: { Authorization: `Bearer ${token}` },
    })

    setSearchList(response.data)
  }

  return (
    <>
      <Button className={clsx(classes.root)} variant="contained" onClick={() => setLinkUserModal(true)}>
        VINCULAR VENDEDOR
      </Button>
      <Card {...rest} className={clsx(classes.card)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Cargo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {store && store.users.map(mapUser => (
                    <TableRow
                      className={classes.tableRow}
                      style={{ cursor: "pointer" }}
                      key={mapUser._id}
                      hover
                      onClick={() => {
                        setModal(true)
                        setUser({
                          storeId: store._id,
                          userId: mapUser._id,
                          name: mapUser.name,
                          code:
                            mapUser.stores[
                              mapUser.stores.indexOf(
                                mapUser.stores.find((link) => link.storeId === store._id)
                              )
                            ].code,
                          type:
                            mapUser.stores[
                              mapUser.stores.indexOf(
                                mapUser.stores.find((link) => link.storeId === store._id)
                              )
                            ].type,
                        })
                      }}
                    >
                      <TableCell style={{
                        alignItems: 'center'
                      }}>
                        <Avatar alt={mapUser.name.complete} src={mapUser.photo.url} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                          <p>{mapUser.name.complete}</p>
                          <p>{mapUser.email || 'E-mail não cadastrado'}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p>{((fs => fs ? fs.type : 'Tipo de usuário não encontrado.')(mapUser.stores.find(us => us.storeId === store._id)))}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        {/* <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metadata.pagination.totalCount}
          onChangePage={(event, page) => onChangePage(page)}
          onChangeRowsPerPage={(event, rows) => onChangeRows(rows)}
          page={metadata.pagination.currentPage - 1}
          rowsPerPage={metadata.pagination.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions> */}
      </Card>
      <EditUserModal
        visible={modal}
        handleClose={() => setModal(false)}
        user={user}
        handleUpdateUser={(user, userId) => {
          dispatch(updateUserLink(user, user.userId, token))
          setModal(false)
        }}
        handleUnlinkUser={() => {
          dispatch(unlinkUser(user.storeId, user.userId, token))
          setModal(false)
        }}
      />
      <LinkUserModal
        visible={linkUserModal}
        handleClose={() => setLinkUserModal(false)}
        userList={userList}
        handleSearch={handleSearch}
        searchList={searchList}
        store={store}
        handleUpdateUser={(user) => {
          dispatch(updateUserLink({
            storeId: user.storeId._id,
            code: user.code,
            type: user.type,
          }, user.userId, token))
          setLinkUserModal(false)
        }}
        handleUnlinkUser={(user) => {
          dispatch(unlinkUser(user.storeId, user.userId, token))
          setLinkUserModal(false)
        }}
      />
    </>
  )
}

Users.propTypes = {
  store: PropTypes.object,
  userList: PropTypes.array,
}

export default Users;