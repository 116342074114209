import produce from 'immer'
import moment from 'moment'

const INITIAL_STATE = {
  input: null,
  inputList: null,
  loading: false,
  modal: false,
  lastUpdate: null,
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 35
    }
  }
}

export default function input(state = INITIAL_STATE, { payload, type }) {
  return produce(state, draft => {
    switch (type) {
      case '@input/REQUEST': {
        draft.loading = true
        break
      }
      case '@input/SUCCESS': {
        draft.input = payload.dataRequest
        draft.loading = false
        break
      }
      case '@input/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/LIST_SUCCESS': {
        draft.inputList = payload.dataRequest ? payload.dataRequest.items : null
        draft.metadata = payload.dataRequest ? payload.dataRequest.metadata : {
          pagination: {
            currentPage: 1,
            pageCount: 1,
            totalCount: -1,
            limit: 35
          }
        }
        // (moment().add(30, 'seconds').unix() - moment().unix()) * 1000
        draft.lastUpdate = moment().unix()
        draft.loading = false
        break
      }
      case '@input/ADD_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/ADD_SUCCESS': {
        draft.inputList.push(payload.dataRequest)
        draft.inputList.sort((a, b) => {
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          return dateB - dateA
        })
        draft.loading = false
        draft.modal = false
        break
      }
      case '@input/UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@input/UPDATE_SUCCESS': {
        const responseId = payload.dataRequest._id
        const inputIndex = draft.inputList.findIndex(
          input => input._id === responseId
        )
        if (inputIndex >= 0) {
          draft.inputList[inputIndex] = payload.dataRequest
        }
        if (draft.input && draft.input._id === responseId) {
          draft.input = payload.dataRequest
        }
        draft.loading = false
        break
      }
      case '@input/DELETE_REQUEST': {
        const inputIndex = draft.inputList.findIndex(
          i => i._id === payload.inputId
        )
        draft.inputList.splice(inputIndex, 1)
        break
      }
      case '@modal/INPUT_OPEN': {
        draft.modal = true
        break
      }
      case '@modal/INPUT_CLOSE': {
        draft.modal = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
