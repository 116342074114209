import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Libs
import moment from 'moment'

// Utils
import { phoneMask, cpfMask } from '../../../../utils/inputMasks'

// Material UI
import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import AvatarInput from '../AvatarInput'

import { useConfirm } from 'material-ui-confirm';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  margin: {
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))


const User = ({
  className,
  loading,
  user,
  companies,
  passwordModal,
  handleOpenModal,
  handleCloseModal,
  handleUpdateUserAvatar,
  handleSubmitData,
  handleUpdatePassword,
  handleBack,
  ...rest
}) => {
  const classes = useStyles()
  const confirm = useConfirm()

  const [defaultValues, setDefaultValues] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    email: '',
    phone: '',
    cpf: '',
    oldPassword: '',
    newPassword: '',
    companyId: '',
  })

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    email: '',
    phone: '',
    cpf: '',
    oldPassword: '',
    newPassword: '',
    companyId: '',
  })

  useEffect(() => {
    if (user) {
      setData({
        firstName: user.name.first,
        lastName: user.name.last,
        birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
        gender: user.gender,
        email: user.email ? user.email : '',
        phone: user.phone,
        cpf: user.cpf,
        password: '',
        companyId: user.companyId,
      })
      setDefaultValues({
        firstName: user.name.first,
        lastName: user.name.last,
        birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
        gender: user.gender,
        email: user.email ? user.email : '',
        phone: user.phone,
        cpf: user.cpf,
        password: '',
        companyId: user.companyId,
      })
    }
  }, [user])

  // Função para popular o estado de data
  const handleChangeData = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const submitData = () => {
    let submitData = {}
    if (defaultValues.firstName !== data.firstName || defaultValues.lastName !== data.lastName) {
      submitData.name = { first: data.firstName, last: data.lastName}
    }

    if (defaultValues.birthDate !== data.birthDate) submitData.birthDate = data.birthDate
    if (defaultValues.gender !== data.gender) submitData.gender = data.gender
    if (defaultValues.email !== data.email) submitData.email = data.email
    if (defaultValues.cpf !== data.cpf) submitData.cpf = data.cpf.replace(/[^a-zA-Z0-9]/g, "",)
    if (defaultValues.phone !== data.phone) submitData.phone = data.phone.replace(/[^a-zA-Z0-9]/g, "",)
    if (defaultValues.companyId !== data.companyId) submitData.companyId = data.companyId

    if (data.password.length > 0) {
      confirm({
        title: 'Atenção!',
        description: `Tem certeza que deseja atualizar a senha do usuário? Essa ação não pode ser desfeita.`,
        confirmationText:
          'Sei o que estou fazendo. Me solta!',
        cancellationText: 'Ops, errei.',
      })
        .then(() => handleSubmitData({
          password: data.password
        }))
        .catch((err) => console.log(err));
    } else {
      handleSubmitData(submitData)
    }
  }


  return (
    <div>
      <Button
        className={classes.button}
        startIcon={<ChevronLeftIcon />}
        onClick={() => handleBack()}
      >
        Usuários
          </Button>
      <Grid spacing={2} container className={classes.container}>
        <Grid item xs={12} md={3}>
          <AvatarInput
            title={user && user.name.complete}
            subTitle={user && user.email}
            image={(user && user.photo.url) || 'https://api.adorable.io/avatars/285/abott@adorable.png'}
            handleSubmitImage={(dataRequest) => handleUpdateUserAvatar(dataRequest)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Card
            {...rest}
            className={clsx(classes.root, className)}
          >
            <form
              autoComplete='off'
              noValidate
            >
              <CardHeader
                subheader='Edite aqui as informações do usuário'
                title='Usuário'
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Nome'
                      margin='dense'
                      name='firstName'
                      onChange={handleChangeData}
                      required
                      value={data.firstName}
                      onFocus={event => event.target.select()}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Sobrenome'
                      margin='dense'
                      name='lastName'
                      onChange={handleChangeData}
                      required
                      value={data.lastName}
                      onFocus={event => event.target.select()}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Email'
                      margin='dense'
                      name='email'
                      onChange={handleChangeData}
                      required
                      value={data.email}
                      onFocus={event => event.target.select()}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='CPF'
                      margin='dense'
                      name='cpf'
                      onChange={handleChangeData}
                      required
                      value={data.cpf}
                      onFocus={event => event.target.select()}
                      variant='outlined'
                      InputProps={{
                        inputComponent: cpfMask,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      label='Telefone'
                      margin='dense'
                      name='phone'
                      onChange={handleChangeData}
                      onFocus={(event) => event.target.select()}
                      value={data.phone}
                      InputProps={{
                        inputComponent: phoneMask,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label='Data de nascimento'
                      margin='dense'
                      name='birthDate'
                      onChange={handleChangeData}
                      required
                      value={data.birthDate}
                      onFocus={event => event.target.select()}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    >
                      <InputLabel>Gênero</InputLabel>
                      <Select
                        id="gender"
                        name="gender"
                        value={data.gender}
                        onChange={handleChangeData}
                        labelWidth={45}
                      >
                        <MenuItem value='female'>Feminino</MenuItem>
                        <MenuItem value='male'>Masculino</MenuItem>
                        <MenuItem value='other'>Outro</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    >
                      <InputLabel id="companyId-label">Empresa *</InputLabel>
                      <Select
                        labelWidth={65}
                        name='companyId'
                        labelId='companyId-label'
                        label='Empresa'
                        onChange={handleChangeData}
                        required
                        value={data.companyId}
                        variant='outlined'
                      >
                        {
                          companies && companies.map((company) => (
                            <MenuItem value={company._id} key={company._id}>{company.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="password"
                      name="password"
                      defaultValue={data.password}
                      onChange={handleChangeData}
                      variant='outlined'
                      value={data.password}
                      label='Senha'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                {/* <Button
                variant='contained'
                className={classes.button}
                onClick={() => {
                  handleOpenModal()
                  setData({
                    ...data,
                    oldPassword: '',
                    newPassword: ''
                  })
                }}
                >
                Alterar senha
              </Button> */}
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.button}
                  onClick={() => submitData()}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} style={{ color: '#fff' }} />
                  ) : (
                      'Salvar'
                    )}
                </Button>
              </CardActions>
            </form>
          </Card >
        </Grid>
        {/* <Dialog
        open={passwordModal}
        fullWidth
        onClose={() => handleCloseModal()}
        maxWidth="xs"
        >
        <DialogTitle>{"Alterar senha"}</DialogTitle>
        <DialogContent>
        <TextField
        className={classes.margin}
        fullWidth
        label='Senha atual'
        type="password"
        name='oldPassword'
        onChange={handleChangeData}
        required
        value={data.oldPassword}
        onFocus={event => event.target.select()}
        variant='outlined'
        />
        <TextField
        className={classes.margin}
        fullWidth
        label='Nova senha'
        type="password"
        name='newPassword'
        onChange={handleChangeData}
        required
        value={data.newPassword}
        onFocus={event => event.target.select()}
        variant='outlined'
        />
        </DialogContent>
        <DialogActions>
        <Button onClick={() => handleCloseModal()} color="primary">
        Cancelar
        </Button>
        <Button onClick={() => submitPassword()} color="primary" autoFocus>
        {loading ? (
          <CircularProgress size={24} style={{ color: '#fff' }} />
          ) : (
            'Salvar'
            )}
            </Button>
            </DialogActions>
          </Dialog> */}
      </Grid>
    </div>
  )
}

User.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.object,
  passwordModal: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  handleCloseModal: PropTypes.func,
  handleSubmitData: PropTypes.func,
  handleUpdatePassword: PropTypes.func,
  handleUpdateUserAvatar: PropTypes.func,
}

export default User
