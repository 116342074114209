import React, { useState } from 'react'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit'
import { formatPrice } from '../../../../../../../../utils/format'

// Componentes
import Modal from './components/Modal'

// Styles
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const Users = ({ input, onUpdateSeller }) => {
  const classes = useStyles()
  const [data, setData] = useState()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '2rem' }} />
            <TableCell>Nome</TableCell>
            <TableCell>Faturamento</TableCell>
            <TableCell>Quantidade de vendas</TableCell>
            <TableCell>Peças Vendidas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {input &&
            input.sellers.map(row => (
              <TableRow
                style={{ cursor: 'pointer' }}
                hover
                onClick={() => setData(row)}
                key={row._id}
              >
                <TableCell align='center'><EditIcon style={{ color: '#63727A' }} /></TableCell>
                <TableCell>{row.sellerId.name.complete}</TableCell>
                <TableCell>{formatPrice(row.sold)}</TableCell>
                <TableCell>{row.sales}</TableCell>
                <TableCell>{row.items}</TableCell>
              </TableRow>
            ))}
          <TableRow
            style={{ backgroundColor: '#EEE', cursor: 'pointer' }}
            key={1000}
            hover
            onClick={() => setData(input.store)}
          >
            <TableCell align='center'><EditIcon style={{ color: '#63727A' }} /></TableCell>
            <TableCell>Outros</TableCell>
            <TableCell>{input && formatPrice(input.store.sold)}</TableCell>
            <TableCell>{input && input.store.sales}</TableCell>
            <TableCell>{input && input.store.items}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Modal
        active={!!data}
        onClose={() => setData()}
        input={data}
        onSubmit={onUpdateSeller}

      />
    </TableContainer>
  )
}

export default Users
