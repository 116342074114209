import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';

import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { userRequest } from '../../store/modules/user/actions';

// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import api from '../../repositories';

const schema = {
  login: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'é obrigatória' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    background: `linear-gradient(to bottom right, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

export default function SignIn({ history }) {
  // const { history, userRequest, user, loading } = props;

  const classes = useStyles();
  const confirm = useConfirm()

  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {},
  });

  const dispatch = useDispatch();
  const loadingRedux = false;
  // const loadingRedux = useSelector(state => state.user.loading);
  const user = useSelector(state => state.user.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (localStorage.getItem('@Matrix:token')) {
      history.push('/dashboard');
    }
  }, [user]);

  useEffect(() => {
    if (loading !== loadingRedux) setLoading(loadingRedux);
  }, [loadingRedux]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = event => {
    setLoading(true);
    event.preventDefault();
    api
      .login(formState.values.login, formState.values.password)
      .then(token => {
        const user = jwt_decode(token);
        if (user.type === 'admin') {
          localStorage.setItem('@Matrix:token', token);
          dispatch(userRequest(user.id, token));
        } else {
          throw new Error('Você não pode acessar a matrix.')
        }
        setLoading(false);
      })
      .catch(err => {
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 401:
              setFormState(formState => ({
                ...formState,
                isValid: false,
                errors: { login: [''], password: ['Login ou senha inválidos'] },
              }));
              break;
            default:
              confirm({
                title: 'Ops!',
                description: 'Deu algum problema...',
                confirmationText: 'Ok!',
                cancellationText: 'Fechar',
              })
              break;
          }
        } else {
          confirm({
            title: 'Ops!',
            description: !err.isAxiosError ? err.message : 'Alguém desligou nosso servidor...',
            confirmationText: 'Ok!',
            cancellationText: 'Fechar',
          })
        }

        setLoading(false);
      });
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <img src="/images/logo.svg" alt="Logo" height="80px" />
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            {/* <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div> */}
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Entrar
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('login')}
                  fullWidth
                  helperText={
                    hasError('login') ? formState.errors.login[0] : null
                  }
                  label="Login"
                  name="login"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.login || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Senha"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                      'Entrar agora'
                    )}
                </Button>
                <Link component={RouterLink} to="/sign-up" variant="h6">
                  Esqueci minha senha
                </Link>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
