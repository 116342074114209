export function companyListRequest(token) {
  return {
    type: '@company/LIST_REQUEST',
    payload: { token },
  }
}

export function companyListSuccess(companies) {
  return {
    type: '@company/LIST_SUCCESS',
    payload: { companies },
  };
}
