import axios from 'axios';

const URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8080/v1'
  // ? 'https://api.proft.sale/v1'
  : 'https://staging.api.neopro.com.br/v1'

// const URL = 'https://api.neopro.com.br/v1'


const login = (login, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${URL}/auth/account`, { login, password })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.token);
        } else {
          reject(response.data.error);
        }
      })
      .catch(err => reject(err));
  });
};

const getUser = (id, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${URL}/user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(err => reject(err));
  });
};

const getInputs = (storeId, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${URL}/input/store/${storeId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(err => reject(err));
  });
};

const getInputPrepare = (storeId, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${URL}/input/store/${storeId}/prepare`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(err => reject(err));
  });
};

const postInput = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${URL}/input`, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch(err => reject(err));
  });
};

export default {
  login,
  getUser,
  getInputs,
  getInputPrepare,
  postInput,
  axios: axios.create({
    baseURL: URL,
    timeout: 5000,
  }),
};
