import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import moment from 'moment'
import api from '../../../repositories/api'

import {
  goalAddSuccess,
  goalListSuccess,
  currentGoalSuccess,
  currentGoalRequest,
  monthGoalSuccess,
  currentGoalFailed
} from './actions'
import { requestFailed } from '../error/action'

export function* goal({ payload }) {
  try {
    const { goalId, token } = payload
    const response = yield call(api.axios.get, `/goal/${goalId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(monthGoalSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* goalAdd({ payload }) {
  try {
    const { goal, token } = payload
    const response = yield call(api.axios.post, '/goal', goal, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(goalAddSuccess(response.data))
    yield put(currentGoalRequest(goal.storeId, token))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* goalList({ payload }) {
  try {
    const { storeId, token, page, limit } = payload
    const response = yield call(api.axios.get, `/goal/store/${storeId}`, {
      params: {
        page,
        limit
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(goalListSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* getMonthGoal({ payload }) {
  try {
    const { token, storeId, date } = payload
    const month = moment(date)
    month.set({
      date: 1,
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    })

    // Busca pelas metas no servidor
    const response = yield call(api.axios.get, `goal/store/${storeId}`, {
      params: {
        limit: 1,
        month: month.toISOString()
      },
      headers: { Authorization: `Bearer ${token}` }
    })

    if (response.data.metadata.pagination.totalCount > 0) {
      yield put(monthGoalSuccess(response.data.items[0]))
    } else {
      requestFailed({
        error: {
          friendlyMsg: 'Meta não encontrada.'
        }
      })
    }
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* getCurrentGoal({ payload }) {
  try {
    const { token, storeId } = payload
    const todayDate = moment()
    todayDate.set({
      date: 1,
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    })

    // Busca pelas metas no servidor
    const response = yield call(api.axios.get, `goal/store/${storeId}`, {
      params: {
        limit: 1,
        month: todayDate.toISOString()
      },
      headers: { Authorization: `Bearer ${token}` }
    })

    if (response.data.metadata.pagination.totalCount > 0) {
      yield put(currentGoalSuccess(response.data.items[0]))
    } else {
      yield put(currentGoalFailed())
    }
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* goalUpdateUsers({ payload }) {
  try {
    const { data, goalId, token } = payload
    const response = yield call(api.axios.put, `/goal/${goalId}/linkUser`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(monthGoalSuccess(response.data))
    toast.success('Meta alterada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* goalDelete({ payload }) {
  try {
    const { goalId, token } = payload
    yield call(api.axios.delete, `/goal/${goalId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    toast.success('A Meta foi deletada com sucesso!')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}


export function* goalEditDay({ payload }) {
  try {
    const { goalId, dayId, data, token } = payload
    const response = yield call(api.axios.put, `/goal/${goalId}/day/${dayId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(monthGoalSuccess(response.data))
    toast.success('Dia alterado com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}


export function* goalEditMainGoals({ payload }) {
  try {
    const { goalId, data, token } = payload
    const response = yield call(api.axios.put, `/goal/${goalId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    yield put(monthGoalSuccess(response.data))
    toast.success('Meta alterada com sucesso')
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@goal/GOAL_REQUEST', goal),
  takeLatest('@goal/LIST_REQUEST', goalList),
  takeLatest('@goal/ADD_REQUEST', goalAdd),
  takeLatest('@goal/CURRENT_REQUEST', getCurrentGoal),
  takeLatest('@goal/MONTH_REQUEST', getMonthGoal),
  takeLatest('@goal/LINK_USER', goalUpdateUsers),
  takeLatest('@goal/DELETE_REQUEST', goalDelete),
  takeLatest('@goal/EDIT_DAY_REQUEST', goalEditDay),
  takeLatest('@goal/EDIT_MAINGOALS_REQUEST', goalEditMainGoals)
])
