import produce from "immer"

const INITIAL_STATE = {
  companyList: null,
  loading: true,
}

export default function input(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@company/LIST_REQUEST": {
        draft.loading = true
        break
      }
      case "@company/LIST_SUCCESS": {
        draft.companyList = action.payload.companies.sort((a, b) => {
          const firstCompany = a.name
          const secondCompany = b.name

          if (firstCompany < secondCompany) {
            return -1
          }

          if (firstCompany > secondCompany) {
            return 1
          }

          return 0
        })
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
