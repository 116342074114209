import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function StoresTable({
  min,
  className,
  stores,
  handleOpenModal,
  onChangeRows,
  onChangePage,
  metadata,
  ...rest
}) {

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>Endereço</TableCell>
                  <TableCell>CEP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores &&
                  stores.length > 0 &&
                  stores
                    .map(s => ({
                      ...s,
                      cnpjMasked: s.cnpj && s.cnpj.replace(
                        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                        "$1.$2.$3/$4-$5"
                      ),
                      cepMasked: s.cep && s.cep.replace(/(\d{5})(\d{3})/g, "$1-$2"),
                      phoneMask: s.phone && s.phone.replace(
                        /(\d{2})(\d{4})(\d{4})/g,
                        "($1) $2-$3"
                      )
                    }))
                    .map(store => (
                      <TableRow
                        className={classes.tableRow}
                        style={{ cursor: "pointer" }}
                        key={store._id}
                        hover
                        onClick={() => handleOpenModal(store._id)}
                      >
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Typography variant="body1">
                              {moment(store.createdAt).format("DD/MM/YYYY")}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>{store.name}</TableCell>
                        <TableCell>{store.email}</TableCell>
                        <TableCell>{store.phoneMask}</TableCell>
                        <TableCell>{store.cnpjMasked}</TableCell>
                        <TableCell>{store.city}</TableCell>
                        <TableCell>{store.address}</TableCell>
                        <TableCell>{store.cepMasked}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={metadata.pagination.totalCount}
          onChangePage={(_event, page) => onChangePage(page)}
          onChangeRowsPerPage={(_event, rows) => onChangeRows(rows)}
          page={metadata.pagination.currentPage - 1}
          rowsPerPage={metadata.pagination.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
}

StoresTable.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
  metadata: PropTypes.object,
  handleOpenModal: PropTypes.func,
  onChangeRows: PropTypes.func,
  onChangePage: PropTypes.func
};
