import { all, call, put, takeLatest } from 'redux-saga/effects'
// import { toast } from 'react-toastify';
import api from '../../../repositories/api'
import moment from 'moment'

import { reportSuccess, monthSuccess, storesReportSuccess } from './actions'
import { requestFailed } from '../error/action'

export function* reportsList({ payload }) {
  try {
    const { storeId, token, range } = payload
    const response = yield call(api.axios.get, `report/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: range
    })
    yield put(reportSuccess(response.data))
    // toast.info('Relatório carregado com sucesso!');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* monthReport({ payload }) {
  try {
    const { storeId, token } = payload
    const firstDay = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0, date: 1 })
    const response = yield call(api.axios.get, `report/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: firstDay.toISOString(),
        to: firstDay.add(1, 'M').subtract(1, 'D').toISOString()
      }
    })
    yield put(monthSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* userStoresReport({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(api.axios.get, `report/user/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    yield put(storesReportSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@report/LIST_REQUEST', reportsList),
  takeLatest('@report/MONTH_REQUEST', monthReport),
  takeLatest('@report/STORES_REQUEST', userStoresReport)
])
