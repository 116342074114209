import React from "react";
import "./config/ReactotronConfig";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import { ConfirmProvider } from "material-ui-confirm";
import validate from "validate.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <Router history={browserHistory}>
          <Routes />
        </Router>
        <ToastContainer autoCLose={3000} />
      </ConfirmProvider>
    </ThemeProvider>
  );
}
