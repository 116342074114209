import { call, put, all, takeLatest } from 'redux-saga/effects'

import api from '../../../repositories'
import { companyListSuccess } from './actions'
import { requestFailed } from '../error/action'

export function* companyList({ payload }) {
  try {
    const { token } = payload;
    const response = yield call(api.axios.get, `/company`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(companyListSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Servidor fora do ar.',
          },
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
              error: {
                friendlyMsg: 'Alguém deve ter desligado nosso servidor...',
              },
            }
        )
      )
    }
  }
}

export default all([
  takeLatest('@company/LIST_REQUEST', companyList),
])
