import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, CardHeader, Card } from '@material-ui/core';

import StoresAmount from './components/StoresAmount';
import UsersAmount from './components/UsersAmount';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const storeListLength = useSelector(
    (state) => state.store.metadata.pagination.totalCount
  );
  const userListLength = useSelector(
    (state) => state.user.metadata.pagination.totalCount
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <StoresAmount stores={storeListLength ? storeListLength : 0} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <UsersAmount users={userListLength ? userListLength : 0} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
