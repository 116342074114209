import produce from "immer";
// import moment from "moment";

const INITIAL_STATE = {
  store: null,
  storeList: null,
  loading: true,
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 10,
    },
  },
  modal: false,
};

export default function input(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@store/REQUEST": {
        draft.loading = true;
        break;
      }
      case "@store/SUCCESS": {
        const { dataRequest } = action.payload;

        draft.store = dataRequest;
        draft.loading = false;
        break;
      }
      case "@store/UNLINK_USER_SUCCESS": {
        const { userId } = action.payload;

        draft.store.users = draft.store.users.filter((u) => u._id !== userId);
        break;
      }
      case "@store/LINK_USER_SUCCESS": {
        draft.store.users = [...draft.store.users, action.payload.dataUser];
        break;
      }
      case "@rows/CHANGE": {
        const { rowsPerParge } = action.payload;
        draft.rowsPerPage = rowsPerParge;
        break;
      }
      // case '@store/ADD_SUCCESS': {
      //   draft.store = action.dataRequest;
      //   draft.loading = false;
      //   break;
      // }
      case "@store/LIST_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@store/LIST_SUCCESS": {
        draft.storeList = action.payload.dataRequest.items;
        draft.metadata = action.payload.dataRequest.metadata;
        draft.loading = false;
        break;
      }
      case "@store/ADD_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@store/ADD_SUCCESS": {
        const { dataRequest } = action.payload;
        draft.storeList = [dataRequest, ...draft.storeList];
        draft.loading = false;
        break;
      }
      case "@store/UPDATE_REQUEST": {
        draft.loading = true;
        break;
      }

      case "@store/UPDATE_SUCCESS": {
        draft.store = {
          ...action.payload.dataRequest,
          users: [...draft.store.users]
        }
        draft.loading = false;
        break;
      }
      case '@store/UPDATE_USER_LINK_SUCCESS': {
        // Busca no array de users o index do usuário que foi alterado
        const userIndex = draft.store.users.findIndex(user => user._id === action.payload.userId)

        // Verifica se esse usuário existe
        if (userIndex > 0) {

          // Busca no array de stores do usuário pela loja que foi alterada
          const storeIndex = draft.store.users[userIndex].stores.findIndex(store => store.storeId === action.payload.dataUser.storeId._id)

          // Altera as informações
          draft.store.users[userIndex].stores[storeIndex].code = action.payload.dataUser.code
          draft.store.users[userIndex].stores[storeIndex].type = action.payload.dataUser.type
        }
        break
      }
      case '@store/AVATAR_UPDATE_REQUEST': {
        draft.loading = true
        break
      }
      case '@store/AVATAR_UPDATE_SUCCESS': {
        draft.store.photo.url = action.payload.url
        draft.loading = false
        break
      }
      case '@integration/SUCCESS': {
        draft.store.integration = action.payload.dataRequest
        break
      }
      case '@integration/DISCONNECT_SUCCESS': {
        draft.store.integration = {}
        break
      }
      case "@modal/OPEN": {
        draft.modal = true;
        break;
      }
      case "@modal/CLOSE": {
        draft.modal = false;
        break;
      }
      default:
        return state;
    }
  });
}
