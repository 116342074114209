import { all } from "redux-saga/effects";

import store from "./store/sagas";
import user from "./user/sagas";
import report from "./report/sagas";
import input from "./input/sagas";
import integration from "./integration/sagas";
import goal from "./goal/sagas";
import company from "./company/sagas";

export default function* rootSaga() {
  return yield all([store, user, report, input, goal, integration, company]);
}
