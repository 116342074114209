import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import api from '../../../repositories/api'

// import {  } from './actions'
import { requestFailed } from '../error/action'
import { storeSuccess } from '../store/actions'
import { integrationSuccess, integrationDisconnectSuccess } from './actions'



export function* integrationRequest({ payload }) {
  try {
    const { storeId, token, data, provider } = payload
    const response = yield call(api.axios.put, `integration/${provider}/store/${storeId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 100000
    })

    yield put(integrationSuccess(response.data))
    toast.info('Integração realizada com sucesso');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export function* integrationDisconnectRequest({ payload }) {
  try {
    const { provider, storeId, token } = payload
    const response = yield call(api.axios.delete, `integration/${provider}/store/${storeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    yield put(integrationDisconnectSuccess(response.data))
    toast.info('Integração removida com sucesso');
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}


export default all([
  takeLatest('@integration/REQUEST', integrationRequest),
  takeLatest('@integration/DISCONNECT_REQUEST', integrationDisconnectRequest)
])
