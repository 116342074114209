import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { colors } from '@material-ui/core'

// Components
// import Header from 'components/Header'
import InputsTable from '../../../../components/InputsTable'
import Input from './components/Input'


// Redux
import {
  inputListRequest
} from '../../../../store/modules/input/actions'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    marginTop: theme.spacing(4)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))

const Inputs = ({ match, history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const store = useSelector(state => state.store.store)

  const inputList = useSelector(state => state.input.inputList)
  const metadata = useSelector(state => state.input.metadata)

  const [tab, setTab] = useState(0)
  const [inputID, setInputID] = useState()



  useEffect(() => {
    if (!inputList) {
      dispatch(inputListRequest(store._id, localStorage.getItem('@Matrix:token')))
    }
  }, [])

  const handleChangePage = (page) => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(inputListRequest(
        store._id,
        window.localStorage.getItem('@Matrix:token'),
        page + 1,
        metadata.pagination.limit
      ))
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(inputListRequest(
      store._id,
      window.localStorage.getItem('@Matrix:token'),
      metadata.pagination.currentPage,
      Number(rowsNumber.props.value)
    ))
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {tab === 0 && (
          <InputsTable
            handleSelectInput={(inputId) => {
              setInputID(inputId)
              setTab(1)
            }}
            inputs={inputList}
            onChangeRows={handleChangeRows}
            onChangePage={handleChangePage}
            metadata={metadata}
          />
        )}
        {tab === 1 && (
          <Input
            inputID={inputID}
            handleGoBack={() => setTab(0)}
          />
        )}
      </div>
    </div>
  )
}

Inputs.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default Inputs
