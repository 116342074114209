import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { Divider, colors, Grid } from '@material-ui/core'
import PieChartIcon from '@material-ui/icons/PieChart'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
// Components
import { formatPrice } from '../../../../utils/format'
import {
  monthRequest
} from '../../../../store/modules/report/actions'
import Users from './components/Users'
import ValueCard from '../../../../components/ValueCard'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    marginTop: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))

const Report = ({ match, history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const report = useSelector(state => state.report.monthReport)
  const store = useSelector(state => state.store.store)

  const reportLoaded = useSelector(state => state.report.currentMonthReportLoaded)


  useEffect(() => {
    if (!reportLoaded) {
      dispatch(monthRequest(store._id, localStorage.getItem('@Matrix:token')))
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='percentual da meta'
            value={report ? (((report.store.total.sold / report.store.goal.sold) * 100).toFixed(1) + '%') : '0%'}
            icon={<PieChartIcon />}
            color='#FFC700'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='faturamento'
            value={formatPrice(report ? report.store.total.sold : 0)}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='quantidade de vendas'
            value={report ? report.store.total.sales : 0}
            icon={<LocalMallIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='peças vendidas'
            value={report ? report.store.total.items : 0}
            icon={<ShoppingBasketIcon />}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Users
          report={report}
        />
      </div>
    </div>
  )
}

Report.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default Report
